import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/jellyfish.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import GartnerTestimonials from '../../components/GartnerTestimonials'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/work-log.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/balance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/ci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const JellyfishPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Jellyfish vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Jellyfish and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Jellyfish vs. Swarmia</>}
        content="Where Jellyfish is a static, top-down dashboard for engineering leaders, Swarmia offers actionable insights for every level of your engineering organization."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading="Why engineering organizations choose Swarmia over Jellyfish"
        content="Jellyfish and Swarmia may look similar on the surface but there are a few fundamental differences that make Swarmia a better fit for modern software organizations."
      />
      <QuoteMosaicBlock
        title="Valuable insights for every level of the organization"
        content={
          <>
            Many engineering leaders are initially satisfied with the insights
            Jellyfish provides. However, it rarely takes them long to realize
            that high-level visibility alone doesn’t help them drive change.
            <br />
            <br />
            That’s why Swarmia caters to not only engineering leaders but also
            the managers and teams who need granular data to make better
            decisions in their daily work.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.silvia}
      />
      <QuoteMosaicBlock
        title="Real-time data"
        content={
          <>
            A recurring piece of feedback we hear from Jellyfish users is that
            the data is often outdated, and therefore difficult to action.
            <br />
            <br />
            With Swarmia, all data is updated in real time, which means you’re
            able to make important decisions based on the latest information.
          </>
        }
        image={getImage(data.mosaicLeft)!}
        quote={quotes.jellyfish1}
      />
      <QuoteMosaicBlock
        title={<>Flexible, self-service configuration</>}
        content={
          <>
            Another frequent frustration among Jellyfish users is that the
            platform is unintuitive and time-consuming to set up. What’s more,
            Jellyfish offers no automated mechanisms to ensure your data stays
            in good shape after the initial onboarding.
            <br />
            <br />
            Swarmia, on the other hand, is easy to both get started with and
            maintain. All settings are available to admin users to make it easy
            to adjust your setup as your data evolves.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        quote={quotes.jellyfish2}
      />
      <QuoteBlock person="maryanna" />
      <CTABlock title={<>Get started with Swarmia today.</>} />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: enterprise-grade features with flexible self-service configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to role-based access and flexible data exports, Swarmia fits the needs of large and growing organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible set up and configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team — at no extra cost."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and roll out."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <GartnerTestimonials />
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title={<>Get started with Swarmia today.</>} />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/size-age-culture-productivity/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default JellyfishPage
